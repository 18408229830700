import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";

export const ModalContainer = styling("div")(({ theme }) => ({
  padding: "60px 0",
}));

export const TextContainer = styling("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: "10px",

  [theme.breakpoints.up("tablet")]: {
    marginTop: "20px",
  },
}));

export const Text = styling("span")((props) => ({
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Inter",
}));

export const EmptyDiv = styling(Grid)((props) => ({
  //
}));

export const ErrorText = styling("span")((props) => ({
  fontSize: "14px",
  fontFamily: "Inter",
  lineHeight: "24px",
  letterSpacing: "0.15px",
}));

export const DialogStyled = styling(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    maxWidth: "600px",
    width: "85%",
  },
}));
