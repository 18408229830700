import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { updatePostApplyDialog } from "../redux/actions/dialog_action";
import {
  applyJob,
  updateSelectedJob,
  updateSelectedJobId,
} from "../redux/actions/job_action";
import { updateSignUpOrigin } from "../redux/actions/navbar_action";
import {
  checkAuthentication,
  updateJobCountryPreference,
  updateJobCountryPreferenceDialog,
} from "../redux/actions/user_action";
import * as jobType from "../redux/types/job_type";
import useTriggerSnackbar from "./useTriggerSnackbar";

export default function useJobApplication(jobOrigin, jobDiscovery) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const user = useSelector((state) => state.user.user);
  const fullUrl = router.asPath;
  const utmUrl = fullUrl.split("?")[1];

  function checkUtmUrl() {
    const lowerUrl = fullUrl.toLowerCase();
    const pattern = /[?&]utm_medium=websitexml(?:&|$)/i;
    const isUtmInUrl = pattern.test(lowerUrl);
    return isUtmInUrl;
  }

  function checkLoggedIn(job) {
    if (!checkAuthentication()) {
      if (checkCrossBorderJobRedirect(job)) {
        return true;
      }

      if (job?.category === "scraped" || job?.category === "aggregated") {
        window.open(job?.externalJobUrl, "_blank");
      }

      dispatch(updateSelectedJob(job)).then(() => {
        dispatch(updateSignUpOrigin(jobOrigin)).then(() => {
          localStorage.setItem("apply_origin", router.asPath);
          router.push("/apply/" + job?.slug);
        });
      });
      dispatch(updateSelectedJobId(job?.id));

      return true;
    }
    return false;
  }

  function checkUtm(job) {
    if (checkUtmUrl() && !checkAuthentication()) {
      dispatch(updateSelectedJob(job)).then(() => {
        dispatch(updateSignUpOrigin("job_aggregator")).then(() => {
          localStorage.setItem("apply_origin", router.asPath);
          router.push("/apply/" + job?.slug + "?" + utmUrl);
        });
      });

      dispatch(updateSelectedJobId(job?.id));
      return true;
    }
    return false;
  }

  // FUNCTION - check no nationality or mobile number or current location
  function checkNationalityPhoneNumberLocation(job) {
    if (
      !user?.nationality ||
      user?.nationality === "undefined" ||
      user?.nationality === "null" ||
      !user?.mobileNumber ||
      user?.mobileNumber === "undefined" ||
      user?.mobileNumber === "null"
    ) {
      localStorage.setItem("apply_origin", router.asPath);
      router.push("/apply/" + job?.slug + "?" + utmUrl);
      return true;
    }
    return false;
  }

  // FUNCTION - check right-to-work and relocation preference in specific country
  // if malaysia, check on non-malaysian
  // if singaporean, check on non-singaporean
  function checkRightToWorkAndRelocate(job, paramsUser = user) {
    const sourceCountry = job?.crossBorderData?.source_country;
    const currentCountry =
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase();
    const state = job?.stateRegion;
    const jobLocation = sourceCountry
      ? sourceCountry
      : state === "Singapore" || state === "singapore"
      ? "SG"
      : currentCountry;

    if (checkRightToWorkAndRelocation(paramsUser, jobLocation)) {
      dispatch(updateJobCountryPreference(jobLocation.toLowerCase())).then(
        () => {
          dispatch(updateJobCountryPreferenceDialog(true));
        }
      );
      return true;
    }
    return false;
  }

  function checkRightToWorkAndRelocation(user, country) {
    const countryCode = country.toUpperCase();

    const jobLocation =
      country === "SG" ? "Singaporean" : country === "MY" ? "Malaysian" : null;

    if (user.nationality === jobLocation) {
      return false;
    } else {
      if (
        !user?.rightToWork?.[countryCode] ||
        Object.keys(user?.rightToWork?.[countryCode] || {}).length === 0 ||
        Object.keys(user?.relocationPreferences?.[countryCode] || {}).length ===
          0
      ) {
        return true;
      }
      // FUNCTION - check last updated right-to-work is 3 months ago
      dayjs.extend(utc);
      const currentTimestamp = dayjs.utc().format();
      const dateAfterThreeMonths = dayjs
        .utc(user?.rightToWork?.[countryCode]?.timestamp)
        .add(3, "months")
        .format();
      const rightToWorkCheck =
        dayjs(currentTimestamp).isAfter(dateAfterThreeMonths);

      return rightToWorkCheck;
    }
  }

  // FUNCTION - INVALID RESUME CHECK
  function checkResumeRejected(job) {
    if (
      !user?.resume ||
      user?.state == "rejected" ||
      user?.state == "no_resume"
    ) {
      localStorage.setItem("apply_origin", router.asPath);
      router.push("/apply/" + job?.slug + "?" + utmUrl);
      return true;
    }
    return false;
  }

  function checkScrapedJob(job) {
    checkCrossBorderJob(job);
    if (job?.category === "scraped" || job?.category === "aggregated") {
      dispatch(
        updatePostApplyDialog({
          status: true,
          scraped: job?.category === "scraped",
          jobGlobalId: job?.globalId,
        })
      );

      window.open(job?.externalJobUrl, "_blank");
      return true;
    }
    return false;
  }

  function checkCrossBorderJobRedirect(job) {
    if (job?.crossBorderData?.source_country === "SG") {
      const url =
        job?.crossedBorder && job?.scraped
          ? job?.crossBorderData?.source_country === "SG" &&
            process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
            ? job?.crossBorderData?.source_country_url +
              `?source=my&origin=${jobDiscovery}`
            : job?.crossBorderData?.source_country_url
          : `/apply/${job.slug}?origin=${jobDiscovery}`;

      const modifiedUrl = url.replace("/jobs/", "/apply/");
      const finalUrl =
        process.env.NEXT_PUBLIC_JSW_ENV === "prod"
          ? modifiedUrl
          : modifiedUrl.replace("https://", "https://staging-");

      window.open(finalUrl, "_blank");
      return true;
    }
    return false;
  }

  function checkCrossBorderJob(job) {
    if (job?.crossBorderData?.source_country === "SG") {
      const url =
        job?.crossedBorder && job?.scraped
          ? job?.crossBorderData?.source_country === "SG" &&
            process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
            ? job?.crossBorderData?.source_country_url +
              `?source=my&origin=${jobDiscovery}`
            : job?.crossBorderData?.source_country_url
          : `/apply/${job.slug}?origin=${jobDiscovery}`;

      window.open(url, "_blank");
      return true;
    }
    return false;
  }

  //   FUNCTION - handle apply job
  async function applyJobFunc(job, params) {
    const response = await dispatch(applyJob(params));

    if (response.type === jobType.APPLY_JOB_FAILED) {
      triggerSnackbarFunc({
        snackbarMessage: response.errMsg ?? "Error",
        severity: "error",
      });
      return { success: false, payload: response.payload };
    } else if (response.type === jobType.APPLY_JOB_SUCCEED) {
      if (user?.state === "approved") {
        triggerSnackbarFunc({
          snackbarMessage: "Application successful!",
          severity: "success",
        });
      }

      dispatch(
        updatePostApplyDialog({
          status: true,
          scraped: job?.category === "scraped",
          jobGlobalId: job?.globalId,
        })
      );

      // if there is external job url, open after apply job for record-keeping
      if (job?.externalJobUrl) {
        window.open(job?.externalJobUrl, "_blank");
      }
      return { success: true, payload: response.payload };
    }
    return { success: false, payload: response.payload };
  }

  return {
    checkUtm,
    checkCrossBorderJob,
    checkNationalityPhoneNumberLocation,
    checkRightToWorkAndRelocate,
    checkLoggedIn,
    checkResumeRejected,
    checkScrapedJob,
    applyJobFunc,
  };
}
