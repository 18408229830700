import React from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import buildingHiredlyResume from "../../../../assets/lotties/resume/build-hiredly-resume.json";
import done from "../../../../assets/lotties/resume/done.json";
import uploadResumeFailed from "../../../../assets/lotties/resume/upload-resume-failed.json";
import uploading from "../../../../assets/lotties/resume/uploading.json";
import {
  updateResumeFailPopupStatus,
  updateResumeProgressPopupStatus,
} from "../../../../redux/actions/user_action";
import { HeightSpacer } from "../../../shared/Spacer/styles";
import {
  DialogStyled,
  EmptyDiv,
  ErrorText,
  ModalContainer,
  Text,
  TextContainer,
} from "./styles";

function ResumeProgressIndicatorPopup(props) {
  const dispatch = useDispatch();

  const isResumeProgressPopupOpen = useSelector(
    (state) => state.user.isResumeProgressOpen
  );

  const isBuildingProgress = useSelector(
    (state) => state.user.isBuildingProgress
  );

  const isUploadingProgress = useSelector(
    (state) => state.user.isUploadingProgress
  );

  const isUploadError = useSelector((state) => state.user.updatingUserCvError);

  const onClosingPopup = () => {
    if (isUploadingProgress) return; // Done allow user to close it when it is in uploading status
    dispatch(updateResumeProgressPopupStatus(false));
    dispatch(updateResumeFailPopupStatus(false));
  };

  return (
    <DialogStyled open={isResumeProgressPopupOpen} onClose={onClosingPopup}>
      <ModalContainer>
        {isBuildingProgress ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: buildingHiredlyResume,
            }}
            width={180}
          />
        ) : isUploadingProgress ? (
          <Lottie
            width={100}
            options={{
              loop: true,
              autoplay: true,
              animationData: uploading,
            }}
          />
        ) : isUploadError ? (
          <Lottie
            width={100}
            options={{
              loop: true,
              autoplay: true,
              animationData: uploadResumeFailed,
            }}
          />
        ) : (
          <Lottie
            width={100}
            options={{ loop: false, autoplay: true, animationData: done }}
          />
        )}
        <TextContainer>
          {isUploadError ? (
            <EmptyDiv container>
              <ErrorText style={{ width: "100%" }}>
                Upload failed due to network error.
              </ErrorText>
              <ErrorText style={{ width: "100%" }}>
                Please try again later.
              </ErrorText>
            </EmptyDiv>
          ) : (
            <Text>
              {" "}
              {isBuildingProgress
                ? "Building Your Resume"
                : isUploadingProgress
                ? "UPLOADING"
                : "DONE!"}
            </Text>
          )}
        </TextContainer>
        <HeightSpacer $height={"10px"} />
      </ModalContainer>
    </DialogStyled>
  );
}

export default ResumeProgressIndicatorPopup;
